@import (reference) 'constants.less';
@import (reference) 'default.less';
@import (reference) 'mixins/box.less';
@import (reference) 'mixins/panel.less';
@import (reference) 'mixins/round-star-label.less';

@import (reference) '../../node_modules/bootstrap/less/variables.less';
@import (reference) '../../node_modules/bootstrap/less/mixins/buttons.less';
@import (reference) '../../node_modules/bootstrap/less/mixins/border-radius.less';
@import (reference) '../../node_modules/font-awesome/less/variables.less';

@header-height: 50px;
@header-logo: '../img/logo.png';
@certificate-logo: '../img/certificate.gif';

html {
    font-size: 8px; // default size for small screens
    @media (min-width: @screen-lg-min) {
        font-size: 9px; //default font size for smaller screens
    }

    @media (min-width: @screen-xlg-min) {
        font-size: 10px; //default font size for most of the desktop screens
    }
}

html, body {
    .height-full;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: @font-size-default;
}

textarea {
    resize: vertical;
}

.theme-dropdown .dropdown-menu {
    position: static;
    display: block;
    margin-bottom: 20px;
}

.theme-showcase > p > .btn {
    margin: 5px 0;
}

.theme-showcase .navbar .container {
    width: auto;
}

.leftPadding15 {
    padding-left: 15px;
}

.margin15 {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: -15px;
    margin-right: -15px;
}

.redColor {
    color: #a94442;
}

.leftfloat {
    float: left;
    padding: 0 3px 0 0;
}

.leftfloatCertificate {
    float: left;
    margin-right: 4px;
}

.hobbyFtHeight {
    height: 50px;
}

.logo-header {
    background-image: data-uri(@header-logo);
    background-repeat: no-repeat;
    background-position: center center;
    min-height: image-height(@header-logo); 

    .container {
        padding-left: 0;
        padding-right: 0;
    }
}
.certificate-image{
    background-image: data-uri(@certificate-logo);
    background-repeat: no-repeat;
    background-position: center center;
    min-height: image-height(@certificate-logo);
}

.navbar {
    border-style: solid;
    border-color: #EFEEEC;
    border-width: 1px 0;
    background: #FFF;
    color: #333;
    font-family: "Oswald",Arial;
    font-size: 1.6rem;

    &.navbar-fixed-top ~ .container {
        > .content {
            padding-top: 70px;
        }
    }

    .navbar-brand {
        background-image: data-uri(@header-logo);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 90px;
    }
}

.personal-menu {
    z-index: 1001;
    position: relative;
}


@media (min-width: 768px) {
    .navbar-center .navbar-nav {
        display: inline-block;
        float: none;
        vertical-align: top;
    }

    .navbar-center .navbar-collapse {
        text-align: center;
    }
}

.navbar-default {
    .navbar .dropdown-menu > li > a {
        color: @pink-strong;
    }

    .navbar-nav {
        & > li {

            @media (min-width: 768px){
                &:after {
                    content: '\00B7';
                    color: #414141;
                    font-weight: 900;
                    font-size: 130%;
                }

                &:last-child:after {
                    content: '';
                }
            }

            & > a {
                color: #414141;
                text-transform: uppercase;
                display: inline-block;
                background-image: none;
                font-family: "Oswald",Arial;
                color: #333;
                border-bottom: solid transparent;

                &:hover,
                &:focus {
                    color: @pink-light;
                }

                &.user-drop-link {
                    color: #a2a2a2;
                    font-size: smaller;
                    text-transform: inherit;
                }

                @media (min-width: 768px) {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            }

            &.active > a {
                &,
                &:hover,
                &:focus {
                    color: @pink-light;
                    background-color: #fff;
                    box-shadow: none;
                    background-image: none;
                }
            }
        }

        & > .open > a {
            &,
            &:hover,
            &:focus {
                color: #FFF;
                background-color: @pink-strong;
                background-image: none;
                border-bottom: solid #F2B0D4;
            }
        }
    }

    .navbar-collapse {
        background-color: #FFF;
        //border-bottom: 1px solid #e7e7e7;
    }

    .navbar-brand {
        width: 111px;

        &,
        &:hover,
        &:focus {
            color: @pink-strong;
        }
    }

    .navbar-toggle {
        background-color: @pink-light;
        border-color: #F2B0D4;

        .icon-bar {
            background-color: #FFF;
        }

        &:hover,
        &:focus {
            background-color: @pink-strong;

            .icon-bar {
                background-color: #FFF;
            }
        }
    }
}

.admin-brand {
    background-image: data-url(@header-logo);
}

pre,
.container .jumbotron,
.panel, .panel-heading,
.form-control,
.list-group,
.list-group-item:first-child,
.list-group-item:last-child,
.img-thumbnail,
.btn {
    border-radius: 0;
}

.panel-body {
    p {
        -ms-word-wrap: break-word;
        word-wrap: break-word;
    }
}


.panel-light-pink {
    .panel(@pink-light);
}

.agency-pad {
    margin-left: 10px;
}

.panel-pink {
    .panel(@pink-strong);
    box-shadow: 3px 3px 3px #888888;
    border-radius: 5px;

    > table > tbody > tr > td {
        vertical-align: middle;
    }
}

.panel-group.todo-list {
    .panel-heading {
        &:hover, &:not(.collapsed) {
            background-color: @pink-light;
            color: white;

            .section-link, .text-muted, .text-success, .text-danger, .text-sticky-note {
                color: white;
            }

            a:hover {
                text-decoration: none;
            }
        }

        .section-link {
            font-weight: bold;
            color: @pink-light;
        }

        &:not(.collapsed) .panel-group-caret:before {
            content: @fa-var-caret-up;
        }

        &.collapsed .panel-group-caret:before {
            content: @fa-var-caret-down;
        }
    }
}

.panel.panel-arrow-down {
    margin-bottom: 25px;

    &:after {
        width: 0;
        height: 0;
        border-left: 12.5px solid transparent;
        border-right: 12.5px solid transparent;
        border-top: 25px solid @pink-light;
        content: "";
        position: absolute;
        left: ~'calc(50% - 12.5px)';
    }
}

.navbar-inverse {
    background-image: ~'linear-gradient(to bottom, ##8E8E8E 0px, #333 100%)';
    background-repeat: repeat-x;

    .navbar-nav > li > a {
        color: #ddd;
    }

    .navbar-brand {
        color: #fff !important;
    }
}

.non-mobile-spacer {
    display: block;
    height: 150px;
}

.usermenuwrapper {
    display: block;
    width: 25%;
    float: right;
    text-align: right;
    margin-right: 10px;
    margin-top: 10px;
}

.aupairpinkbadge {
    background-color: #DA3884;
}

.footer-address {
    text-align: left;
    color: #fff;
}

.footer-contact {
    text-align: left;
    color: #fff;
}

.bs-docs-social {
    text-align: left;
}

.page-header {
    margin: 0 0 15px;
}

.has-success .form-control-note {
    color: #3c763d;
}

.form-control-note {
    display: block;
    height: 34px;
    line-height: 34px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 25px;
    width: 34px;
    z-index: 2;
}

.has-success .form-control-help {
    color: #3c763d;
}

.form-control-help {
    display: block;
    height: 34px;
    line-height: 34px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 25px;
    width: 34px;
    z-index: 2;
}

.section-header {
    border-bottom: solid 1px #eee;
    padding-bottom: 5px;
}

@media (min-width: 768px) {
    .toolbar {
        top: 51px;
        bottom: 0;
        left: 0;
        z-index: 1000;
        display: block;
        padding: 20px;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
        background-color: #f5f5f5;
        border-right: 1px solid #eee;
        float: right;
    }
}

.container .jumbotron {
    background: url('../img/jumbo_back_ny.png') no-repeat center center;
    color: #FFF;
}

.jumbotron h2 {
    text-transform: uppercase;
}

.boxes {
    color: white;
    text-align: center;

    a.first-box,
    a.second-box,
    a.third-box {
        display: block;
        color: inherit;
        text-decoration: inherit;
    }

    .first-box,
    .second-box,
    .third-box {
        min-height: 390px;
        margin-bottom: 20px;
        padding-bottom: 5px;
    }

    .image {
        width: 100%;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .content {
        padding: 0 20px;
    }

    .first-box {
        .box(rgb(216, 61, 132), '/img/landing_pic1.png');
    }

    .second-box {
        .box(rgb(78, 189, 200), '/img/landing_pic2.png');
    }

    .third-box {
        .box(rgb(255, 187, 66), '/img/landing_pic3.png');
    }
}


.btn-flat {
    filter: progid:DXImageTransform.Microsoft.Gradient(enabled='false');
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-image: none;
    border: 0;
}

.has-feedback {
    .form-control {
        padding-right: 12px;
    }

    .form-control-feedback {
        top: 0 !important;
        right: -36px;
        width: 44px;
        text-align: left;
    }
}

.form-horizontal .has-feedback {
    .form-control-feedback {
        right: -36px;
        width: 44px;
        text-align: left;
    }

    .form-control-help,
    .form-control-note {
        right: 15px;
        top: 0;
    }

    .text-left {
        text-align: left;
        margin-bottom: 0;
        padding-top: 7px;
        padding-left: 30px;
    }
}


.col-sm-11Half {
    width: 97%;
    float: left;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

.radio, .checkbox {
    margin-top: -5px;
}

.form-control-feedback .glyphicon {
    width: 20px;
    text-align: center;
}

.control-label .glyphicon-info-sign {
    color: #428bca;
}

.align-tickYN .form-control-feedback {
    right: 55px !important;
    top: -4px !important;
    display: none;
}

.align-tickUW .form-control-feedback {
    right: -35px !important;
    top: -4px !important;
}

.licenceFrom .form-control-feedback {
    display: none;
}

.printContainer {
    width: 830px !important;
    margin: 10px auto 100px auto;
}

.signIn {
    border-color: #eee;
    border-width: 0 1px 0 0;
    border-style: solid;
}

.terms {
    height: 500px;
    overflow-y: scroll;
    overflow-x: auto;
}

.tooltip-inner {
    width: 500px !important;
}

.info-block {
    color: #737373;
}

textarea.line-2 {
    height: 54px;
}

textarea.line-4 {
    height: 94px;
}

textarea.line-6 {
    height: 134px;
}

textarea.line-8 {
    height: 1754px;
}

textarea.line-10 {
    height: 214px;
}

textarea.line-12 {
    height: 254px;
}

textarea.line-14 {
    height: 294px;
}

textarea.line-16 {
    height: 334px;
}

textarea.line-18 {
    height: 374px;
}

textarea.line-20 {
    height: 414px;
}

.hostFamilyLetterContainer {
    background: none;
    border: none;
    font-family: 'Oxygen', sans-serif;
    font-size: 13px;
    white-space: pre-wrap; /* CSS 3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    -ms-word-wrap: break-word;
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.registerButtonsBlock > div {
    padding-bottom: 16px;
}

.message {
    display: block;
    width: 80%;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;

    &.sent {
        background-color: #D5F7CD;
        float: right;
    }

    &.received {
        background-color: #E5E5E5;
        float: left;
    }

    .message_header {
        border-bottom: solid 1px #DDD;
        padding: 0 5px 0 0;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .message-text {
        white-space: pre-wrap;
    }
}

.list-group-item.list-group-item-center:first-child {
    text-align: center;
}

.list-group-item-center {
    list-style-type: none;
}

.btn-pink {
    .button-variant(#fff, @pink-strong, #F2B0D4);
    background-image: linear-gradient(to bottom, #EA7AB7 0, @pink-strong 100%);
    background-repeat: repeat-x;
    border-color: #D42586;

    .badge {
        color: #EA7AB7;
    }
}

.btn-pink-plain {
    .button-variant(#fff, @pink-strong, @pink-light);
}

.nav-pills > li {
    > a {
        border-radius: 0;
        border-left: solid #FFF 1.25rem;

        &:hover,
        &:focus {
            color: #FFF;
            background-color: @pink-light /*F2B0D4  EB82BD*/;
            border-left: solid #F2B0D4 1.25rem;
        }
    }

    &.active > a {
        &,
        &:hover,
        &:focus {
            background-color: @pink-strong;
            border-left: solid @pink-light 1.25rem;
        }
    }
}

fieldset {
    margin-bottom: 20px;
}

.badgeerror {
    background-color: @error-colour;
}

.bs-docs-footer {
    background-color: #414141;
    font-size: small;
    color: #fff;
    margin-top: 100px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    position: relative;
    h3 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 5px;
        width: 100%;
        border-bottom: solid 1px #808080;
    }

    & ul&-links {
        & li + li:before {
            content: " | ";
            padding: 0 5px;
        }

        a {
            color: white;
        }
    }
}

.footer-terms {
    border-top: solid 1px #808080;
    text-align: left;
    padding-top: 10px;
    font-size: @font-size-smaller;
}

.message-block, 
.message-block-sm {
    padding: 12px;

    &.bg-success {
        color: #3c763d;
    }
}

.message-block {
    font-size: 20px;
}

.userMedia {
    margin-top: 30px;

    .carousel-container {
        padding: 0 12px;
        /*height: 244px;*/
    }

    .carousel-control {
        background: none;
        width: 20px;
        cursor: pointer;

        span {
            color: black;
        }
    }

    .carousel-indicators li {
        width: 13px;
        height: 13px;
        margin: 0 1px 0 1px;
    }
}

// part of .userMedia?!
.carousel-indicators {
    bottom: -40px;

    li {
        border-color: #FFF;
        background-color: #D0D0D0;
    }

    .active {
        background-color: #303030;
    }
}

.carousel-inner {
    margin-bottom: 10px;
}

#highlightField {
    margin-bottom: 0 !important;
}

.upload .preview,
.page .thumbnail {
    position: relative;
    width: 100%;

    a.remove {
        color: red;
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 2px;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        background: #fff;
        height: 24px;
        width: 24px;
        text-align: center;
        border-radius: 0px 4px 0px 4px;
    }

    .progress {
        bottom: 8px;
        left: 8px;
        position: absolute;
        right: 8px;
        margin-bottom: 0;
    }

    & img.multi-upload.photo,
    & img.multi-upload.add,
    & .preview {
        max-height: 120px;
    }
}

.profile-photo {
    width: 250px;
    height: 300px;
}

.avatar-display {
    display: inline-block;
    background-position: center;
    background-size: cover;
    text-align: center;   
}

.avatar-display:before {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
}

.portfolio-item {
    &.doc {
        border: 1px solid #ccc;
        cursor: pointer;
    }

    img {
        width: 100%;
    }

    p {
        height: 38px;
        overflow: hidden;
    }
}

.upload-doc-add {
    cursor: pointer;
}

.upload-doc-add, 
.preview-unavailable {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: table;
    background-color: #eee;
    color: #999;

    span {
        vertical-align: middle;
        text-align: center;
        display: table-cell;
    }
}

.preview-unavailable span {
    padding: 120px 0;
}

.btn-social-right {
    position: relative;
    padding-right: 44px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;

    & > :first-child {
        position: absolute;
        right: 0;
        left: auto;
        top: 0;
        bottom: 0;
        width: 32px;
        line-height: 34px;
        font-size: 1.6em;
        text-align: center;
        border-left: 1px solid rgba(0,0,0,0.2);
        border-right: none;
    }
}

.modyfied {
    background-color: yellow;
    color: black;

    &.old {
        background-color: lightgray;
    }
}

.sticky-note {
    position: relative;
    padding: 6px;
    margin-bottom: 10px;
    font-size: 90%;
    background-color: @stickyNote-bg-colour;
    border: 1px solid @stickyNote-border-colour;
    background: linear-gradient(195deg, @stickyNote-border-colour, @stickyNote-bg-colour 15% );
    box-shadow: 2px 2px 4px -2px;
    margin-left: 10px;
    margin-right: 10px;

    &.done {
        background-color: @stickyNote-completed-bg-colour;
        border: 1px solid @stickyNote-completed-border-colour;
        background: linear-gradient(195deg, @stickyNote-completed-border-colour, @stickyNote-completed-bg-colour 15% );
    }

    .saving {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        span {
            top: 50%;
            left: 50%;
            display: block;
            position: absolute;
            margin-top: -10px;
            margin-left: -20px;
        }
    }

    textarea,
    textarea:hover,
    textarea:focus {
        width: 100%;
        background: none;
        border: none;
    }
}


.box {
    font-size: @font-size-smaller;
    padding: 1px 10px;
    position: relative;

    .btn {
        position: absolute;
        color: white;
        bottom: 10px;
        left: 50%;
        margin-left: -50px;
        width: 100px;

        &.active {
            background-color: white;
        }
    }

    @media (min-width: 768px) {
        min-height: 300px;
    }
}

.programSelection .boxes {

    .box {
        .cursor-pointer;
        .user-select-none;
        min-height: 100px;
    }
    //TODO:: only if at least one is active
    .box:not(.active) {
        opacity: 0.5;
    }

    i {
        padding-bottom: 5px;
    }
}

videogular vg-media, [videogular] vg-media {
    height: auto !important;
}

.affix-bottom {
    position: absolute;
}

.affix {
    position: fixed;
    top: @header-height + 10px;
}

.affix-workqueue {
    /*position: fixed;*/
    width: 30%;
}

.thumbnail, .img-thumbnail, .highlights-video, .view-certificate {
    cursor: pointer;
}

.jcrop-holder {
    direction: ltr;
    text-align: left;
}

/*.jcrop-vline, .jcrop-hline {
    background: #FFF url(Jcrop.gif);
    font-size: 0;
    position: absolute;
}*/

.jcrop-vline {
    height: 100%;
    width: 1px !important;
}

.jcrop-vline.right {
    right: 0;
}

.jcrop-hline {
    height: 1px !important;
    width: 100%;
}

.jcrop-hline.bottom {
    bottom: 0;
}

.jcrop-tracker {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
    width: 100%;
}

.handle {
    background-color: #333;
    border: 1px #EEE solid;
    font-size: 1px;
    height: 14px;
    width: 14px;
}

.handle.ord-n {
    left: 50%;
    margin-left: -7px;
    margin-top: -7px;
    top: 0;
}

.handle.ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -7px;
    margin-left: -7px;
}

.handle.ord-e {
    margin-right: -7px;
    margin-top: -7px;
    right: 0;
    top: 50%;
}

.handle.ord-w {
    left: 0;
    margin-left: -7px;
    margin-top: -7px;
    top: 50%;
}

.handle.ord-nw {
    left: 0;
    margin-left: -7px;
    margin-top: -7px;
    top: 0;
}

.handle.ord-ne {
    margin-right: -7px;
    margin-top: -7px;
    right: 0;
    top: 0;
}

.handle.ord-se {
    bottom: 0;
    margin-bottom: -7px;
    margin-right: -7px;
    right: 0;
}

.handle.ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -7px;
    margin-left: -7px;
}

.jcrop-dragbar.ord-n, .jcrop-dragbar.ord-s {
    height: 7px;
    width: 100%;
}

.jcrop-dragbar.ord-e, .jcrop-dragbar.ord-w {
    height: 100%;
    width: 7px;
}

.jcrop-dragbar.ord-n {
    margin-top: -4px;
}

.jcrop-dragbar.ord-s {
    bottom: 0;
    margin-bottom: -4px;
}

.jcrop-dragbar.ord-e {
    margin-right: -4px;
    right: 0;
}

.jcrop-dragbar.ord-w {
    margin-left: -4px;
}

.jcrop-light .jcrop-vline, .jcrop-light .jcrop-hline {
    background: #FFF;
    filter: alpha(opacity=70) !important;
    opacity: .70 !important;
}

.jcrop-light .jcrop-handle {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background-color: #000;
    border-color: #FFF;
    border-radius: 3px;
}

.jcrop-dark .jcrop-vline, .jcrop-dark .jcrop-hline {
    background: #000;
    filter: alpha(opacity=70) !important;
    opacity: .7 !important;
}

.jcrop-dark .jcrop-handle {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background-color: #FFF;
    border-color: #000;
    border-radius: 3px;
}

.solid-line .jcrop-vline, .solid-line .jcrop-hline {
    background: #FFF;
}

.jcrop-holder img, img.jcrop-preview {
    max-width: none;
}

.cc-entry {
    display: block;
    background-color: @error-colour;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    margin-right: 0.3em;
    width: 48%;
    float: left;
}

.placeholder {
    border: solid 1px #D6F3D1;
    text-align: center;
    background-color: #F3FFF1;
    text-decoration: none;
    margin-bottom: 0.3em;
    padding: 0;
    cursor: pointer;

    &:hover {
        background-color: #D3FFD5;
    }

    & > div {
        & > span {
            display: block;
            float: left;
            margin-top: -10px;
            margin-left: -10px;
        }
        /* if required and optional are classes only on span elements we can move these two a level down */
        & > .required {
            color: @error-colour;
        }

        & > .optional {
            color: green;
        }
    }
}

.required-item {
}

.cal-slide-box {
    display: none !important;
}

.cal-month-day.sel {
    background-color: #E5FBFA;
}

.calendar-event {
}

.calendar-event-icon {
    float: left;
}

.calendar-event-action {
    float: right;
    cursor: pointer;
}

.Interview .calendar-event-icon {
    color: #b94a48;
}

.calendar-event-content {
    margin-left: 20px;
    margin-right: 20px;
}

.cal-events-num.Interview {
    background-color: #b94a48;
}

.timepicker a.btn.btn-link {
    padding: 0;
}

.app-progress i {
    padding: 4px;
    font-size: @font-size-smaller;
    color: white;
    border-radius: 2px;
    background-color: #bbb;

    &.incomplete, &.unknown {
        background-color: #bbb;
    }

    &.needAction {
        background-color: orange;
    }

    &.complete {
        background-color: forestgreen;
    }
}

.expiryWarning {
    color: @error-colour;
}

/* Buttons */
.btn-action {
    background-image: linear-gradient(to bottom, #337ab7 0, #265a88 100%) !important;
}

.btn-cancel {
    background-image: linear-gradient(to bottom, #337ab7 0, #265a88 100%) !important;
}

.btn-delete {
    background-image: linear-gradient(to bottom, #337ab7 0, #265a88 100%) !important;
}

.btn-size {
    width: 100px  !important;
    height:30px;

}

.ap-message {
    margin-top: 56px;
}

.picture, .video {
    background-color: #eee;
    color: #999;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
}


.grid-item {
    margin-bottom: 30px;
    cursor: pointer;
    /*display: table;*/
    > div {
        position: relative;
        overflow: hidden;
    }

    p.desc {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        background-color: rgba(255,255,255,0.5);
        margin: 0;
        color: #333;
        padding: 6px;
    }


    img {
        width: 100%;
    }
}

.grid-item-small > div {
    height: 195px;
}

.grid-item-big > div {
    height: 415px;
}

@application-menu-width: 300px;

.grid-td-last-comment-text {
    overflow: hidden;
    text-overflow: ellipsis; 
    display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3; 
   line-height: 120%;  
}


 @media (min-width: 992px) {

    .application-menu {
        width: @application-menu-width;
    }

    .simple-layout .application-menu {
        float: left;
    }

    .advance-layout .application-menu {
        float: left;
    }
 }



.application-menu {
    padding: 0px 15px;

    ul.nav {
        li.active > a,
        li > a:hover,
        li > a:focus {
            i.fa {
                color: inherit;
            }
        }
    }
}

.application-content, .main-content {
    @application-content-alert-z-index: 5;

    .main-content & {
        @media (min-width: 992px) {
            margin-left: @application-menu-width;
        }
        padding: 0px 15px;
    }

    .application-content-header {
        padding: 10px;
        z-index: @application-content-alert-z-index;
    }

    .application-content-footer {
        background-color: @pink-light;
        color: #FFF;
        top: inherit;
        bottom: 0;
        height: 64px;
        z-index: @application-content-alert-z-index;

        .application-content-footer-content {
            padding: 15px;
        }
    }

    .application-content-footer-without-background {
        color: #FFF;
        top: inherit;
        bottom: 0;
        height: 64px;
        z-index: @application-content-alert-z-index;

        .application-content-footer-content {
            padding: 15px;
        }
    }
}

.ribbon-success {
    .round-star-label(#1abc9c, 30px);
    position: absolute;
    top: 10px;
    right: 10px;

    & .fa.fa-lg {
        color: white;
        left: 4%;
        top: 22%;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 99;
    }
}

.documentsList {
    .dl-horizontal {

        dt {
            width: 60px;
        }

        dd {
            margin-left: 80px;
        }
    }

    .btn-group-vertical {
        width: 100%;
    }

    .row {
        margin-bottom: 15px;
    }
}

.right-margin {
    margin-right: 5px;
}

.childcare-radiobutton {
    margin-top: 5px;
}

.applicant-search {
    @applicant-search-width-lg: 350px;
    @applicant-search-width-sm: 150px;


    #applicantSearch {
        width: @applicant-search-width-sm;
    }

    .dropdown-menu {
        width: @applicant-search-width-sm;
    }

    @media (min-width: @screen-xlg-min) {
        #applicantSearch {
            width: @applicant-search-width-lg;
        }

        .dropdown-menu {
            width: @applicant-search-width-lg;
        }
    }

    .fullname-style {
        font-weight: bold;
        font-size: @font-size-default;
    }

    .typeahead-wrapper {
        display: block;
        height: 50px;
        padding-top: 5px;
    }

    .typeahead-photo {
        float: left;
        width: 40px;
        margin: 0 8px 0 -8px;
    }

    .typeahead-labels {
        float: left;
        height: 40px;
    }

    .typeahead-primary {
        font-weight: bold;
    }

    .no-match-margin {
        margin-left: 10px;
    }

    .resulTypeHeading {
        font-weight: bold;
        padding: 3px 0px 30px 10px;
        height: 30px;
        font-size: 18px;
        border-bottom: 1px solid grey;
    }
}

.interviewer-profile {
    display: flex;
    overflow-x: hidden;

    .profile-image {
        float: left;
        width: 295px;
        margin-right: 20px;
    }

    .profile-form {
        float: left;
        width: 100%;
    }

    .profileForm {
        width: 1100px;
    }
}


.helpText {
    margin-top: -6px;
    margin-bottom: 8px;
    font-style: italic;
    font-size: 13px;
    color: #666;
}

.text-italic {
    font-style: italic;
}

.placement-details {
    @media (min-width: 768px) {
        .dl-horizontal {
            dt {
                width: 240px;
                text-align: left !important;
                margin-bottom: 10px;
            }

            dd {
                margin-left: 250px;
                margin-bottom: 10px;
            }
        }
    }
}

ul.resources {
    color: #337ab7;
    list-style: none;
    padding: 0;

    li {
        padding: 4px 0px;

        a {
            cursor: pointer;
            text-decoration: none;
        }
    }

    .no-uploaded {
        color: #000;
        font-style: italic;
    }
}

.printRefereeContainer {
    width: 800px !important;
    margin: 10px auto 100px auto;
}

.printRefereeContainer .checkbox {
    /*width: auto;*/
    margin-top: 1px;
}

.printRefereeContainer > input {
    width: 400px;
}

.bulk-message, .grid-export {
    margin-top: 10px;
    margin-bottom: 10px;
}

.uploaded-image-gallery > img {
    width: 300px;
    height: 300px;
    padding: 5px;
    cursor: pointer;
}

.reference-view {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f9f9f9;
}

.reference-column-width-default > td {
    width: 25%;
}

.flight-date-tooltip > .tooltip > .tooltip-inner {
    max-width: 160px;
    color: #fff;
    text-align: left;
    background-color: #000;
}

.accept-application-tooltip > .tooltip > .tooltip-inner {
    max-width: 350px;
    color: #fff;
    text-align: left;
    background-color: #000;
}

.disabled-accept-application {
    opacity: .65;
    cursor: no-drop;
}

.padding-0 {
    padding: 0px;
}

.justifyText {
    text-align: justify;
}

.interestedHF > hr {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
}

.PortraitPhoto {
    img {
        width:240px;
        height: auto;
        margin: 0px;
    }
}

@media (max-width: 605px) {
    .familyDetails tbody td {
        display: block;
        width: 100%;
    }
}

.work-queue-button > span {
    color: #265a88;
    cursor: pointer;
}
.work-queue-selected-button,
.searchType .active {
    background-color: #E35FA7;
}
.work-queue-selected-button, .work-queue-selected-button:hover, .work-queue-selected-button:focus,
.searchType .active, .searchType .active:hover, .searchType .active:focus {
    background-image: linear-gradient(to bottom, #EB82BD 0%, #da2b8a 100%) !important;
    background-position: 0;
    border-color: #D42586;
}

/*.work-queue-default-button{
    background-color: #E35FA7;
}
.work-queue-default-button, .work-queue-default-button:hover, .work-queue-default-button:focus {
    background-image: linear-gradient(to bottom, #EB82BD 0%, #da2b8a 100%) !important;
    color: #fff !important;
    font-weight: bold;
    background-position: 0;
    border-color: #D42586;
}

.work-queue-selected-button, .work-queue-selected-button:hover, .work-queue-selected-button:focus {
    background-image: linear-gradient(to bottom, #337ab7 0%, #265a88 100%);
    font-weight: bold;
    color: gold;
    background-position: 0;
}*/

.margin-left-negative-5 {
    margin-left: -5px !important;
}

.user-settings {
    padding: 10px;
}

.user-country-settings .has-feedback > div {
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    width: 400px;
    height: 500px;
    overflow-y: scroll;
    padding: 10px;
    margin-left: 15px;
}

.workqueue-filter {
    padding: 10px;
}

.workqueue-filter-date [aifs-form] > div {
    width: 20%;
    margin-top: 10px;
}

.work-queue-selected {
    color: #da2b8a;
}

select.width-less-10-per{
    display:inline-block;
}

.width-size-438{
     width: 438px;
    display: inline-block;
}

.margin-right-10{
    margin-right: 10px;
}

.margin-left-390 {
    margin-left: 390px;
}

.margin-left-10 {
    margin-left: 10px;
}
.margin-left-15 {
    margin-left: 15px;
}
.margin-right-15 {
    margin-right: 15px;
}
.margin-bottom-1 {
    margin-bottom: 1px;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-2 {
    margin-top: 2px;
}

.error-submit{
    font-size:15px;
    color:#a94442;
}

.error-submit-reason-answer {
    border: 1px solid #a94442;
}

.error-submit-interviewed-answer{
    color:#a94442;
}

.flight-detail-leg-header {
    background-color: rgba(181, 158, 200, 1);
    padding: 1px;
}

.flight-detail-leg-header span {
    font-weight: bold;
    color: white;
    margin: 5px;
}

.user-profile-picture-flying {
    height: 250px;
    width: 200px;
}

.noright_box {
    padding: 10px;
    color: #666666;
    position: relative;
}

.bg_purple {
    background-color: #8066AD;
    color: #ffffff;
}

.alignMiddle {
    vertical-align: middle;
}

.shared-flight-details {
    background-color: rgba(245,245,245,245);
}


.reference-control-width-default [aifs-dropdown] {
    width: 400px;
}

[aifs-dropdown] option:disabled {
    color: lightgrey;
}

.dropdown-max-width {
    width: 400px;
}

.margin-0 {
    margin: 0;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-negative-20 {
    margin-bottom: -20px;
}

.margin-bottom-negative-10 {
    margin-bottom: -10px;
}

.margin-top-negative-15 {
    margin-top: -15px;
}

.padding-right-10 {
    padding-right: 10px;
}

.padding-left-15 {
    padding-left: 15px;
}

.workqueue-column-width-auto {
    width: auto;
}

.activity-show-date {
    color: black;
    font-size: x-small;
}

.activity-show-thumbnail-image {
    float: left;
    width: 40px !important;
    height: 35px !important;
    margin-right: 10px;
}
ul.applicant-info li.list-group-item {
    padding: 0 !important;
    border-top: 0 !important;

    > div {
        padding: 10px 15px;
        border-top: 1px solid #ddd;
    }
}

.frame-height {
   height: ~'calc(100% - 12px)';
}

.sidebar ul.nav.nav-sidebar li > a {
  padding: 10px;
  border-bottom: solid 1px #DEDEDE;
  text-align: center;
  display:-webkit-box!important;
}

.sidebar.expanded {
  width: 20%;
}

.sidebar.expanded ~ .main-container {
  padding-left: 20%;
}
@media (min-width: 1920px) {
  .sidebar.expanded {
    width: 18%;
  }
  .sidebar.expanded ~ .main-container {
    padding-left: 18%;
  }
}

@media (min-width: @screen-xs) {
    .sidebar.expanded ul.nav.nav-sidebar li > a {
        text-align: inherit;
        padding: 10px 30px 0px 0px;
    }

    .sidebar ul.nav.nav-sidebar li:not(.summary) > a {
        height: 45px;
    }
}

@media (max-width: @screen-xs) {
    .sidebar.expanded ul.nav.nav-sidebar li > a {
        text-align: inherit;
        padding: 8px 20px 8px 0px;
        word-break: break-word;
    }

    .sidebar ul.nav.nav-sidebar li:not(.summary) > a {
        min-height: 20px;
    }

    .sidebar.expanded ul.nav.nav-sidebar li:not(.summary) > a > i {
        right: 5px;
        top: 5px;
        position: absolute;
    }

    .sidebar.expanded ul.nav.nav-sidebar .subnav li > a {
        padding: 8px 5px;
        word-break: break-word;
    }

    .sidebar.expanded ul.nav.nav-sidebar .subnav li > a > i.fa,
    .sidebar.expanded ul.nav.nav-sidebar .subnav li > a > i.glyphicon {
        display: none;
    }
}

@media print {
  a[href]:after {
    content: none !important;
  }
}

.reference-information {
    color: #da2b8a;
    font-size: 15px;
}
@media (min-width: 1920px) {
  .reference-information {   
    font-size: 18px;
  }
}

.todo-list-style {
    background-color: #DC3492;
    color: #ffffff;
    font-weight: bold;
    //font-family: 'Bookman Old Style', 'Book Antiqua', Verdana;
    //height: 25px;
    //padding: 4px;
    padding: 8px;
}
@media (min-width: 1920px) {
  .todo-list-style {   
    height: 30px;
    padding: 5px;
  }
}

.note-footer-text{
    color: #337A7D;
    font-weight:bold;
}

.right-align-text {
    text-align: right;

    .sticky-note-icon {
        color:#F6E98B;
        top: 5px;
        text-align: center;
        font-size: 21px;
    }
}

.reference-column-size{
    .size-35{
        width:35%;
    }
    .size-20{
        width:20%;
    }
    .size-15{
        width:15%;
    }
    .size-5{
        width:5%;
    }
}
@media (max-width: 767px){
    .navbar-fixed-top .main-menu {
        margin-left: -15px;
        margin-right: -15px;
    }
}

.scrollable-documents{
    overflow:auto;
    height:80%;
}
.height-div-100{
    height:100%;
}


.navbar-default .user-info {
    float: right;
    display: table;
    width: 126px;
    height: 50px;
    text-align: right;
    margin-right: 8px;
}

.navbar-default .user-info > * {
    display: table-cell;
    vertical-align: middle;
}



.nav-mobile li {
    display: inline-block;
    border: 1px solid #e7e7e7;
    margin: 1px 2px;
}

.nav-mobile {
    text-align: center;
    margin-bottom: 10px;
}

.nav-mobile.nav-pills > li > a {
    border: 0px !important;
}

@media (max-width: 991px) {
    .applicant-info .collapse {
        height: auto !important;
        display: block !important;
    }
}

@media (max-width: 767px){
    .navbar-default .navbar-nav > li > a {
        width: 100%;
        text-align: center;
    }

    .navbar-default .navbar-collapse {
        border-bottom: 1px solid #e7e7e7;
    }
}

.navbar-default .navbar-nav > li.divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: inherit;
}


@media (max-width: 767px){
    .navbar-default.navbar-static-top > .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
    }
}
@media (max-width: 1199px){
    .affixSave .btn{
        font-size: 12px;
    }
}
.activity-has-interviewer {
    color: red;
}
div.notificationmessage {
    color: white;
    background-color: #DC3492;
    font-weight: bold;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 12px;
}

.navbar a.toggle {
    width: 100%;
    line-height: 48px;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    color: #FFF;
    background-color: #da2b8a;
}

.height-half {
    height: 50%;
}

.notbold {
    font-weight: normal;
}

.flight-message {
    background-color: #da2b8a;
    color: white;
    padding: 6px;
    margin: 10px 0px;
    text-align: justify;
}

.navbar-caret {
    line-height: 48px;
    padding: 0 18px;
    position: absolute;
    right: 0;
    top: 0;
}

.btn-margin-bottom-15 {
    margin-bottom:15px;
}

.workqueues .queue {
    display: block;
    width: 100%;
    margin-bottom: 6px;
    text-align: left;
}

.workqueues .user-filters .queue {
    display: table;
}

.workqueues .user-filters .queue > div {
    display: table-cell;
}

.workqueues .user-filters .queue > div:first-child {
    width: 100%;
}

.workqueues .user-filters .queue button {
    width: 100%;
    text-align: left;
}

.cursor-default{
    cursor:default;
}

.smallDiv
{   
    display: inline-block;
    vertical-align: middle;  
    transition: font-size linear 1s;
    color:white !important;
}

.btn-youtube{
    color:#fff;
    background-color:#DC2725;
    border-color:rgba(0,0,0,0.2)

}
.btn-youtube:hover,.btn-youtube:focus,.btn-youtube:active,.btn-youtube.active,.open>.dropdown-toggle.btn-youtube{
    color:#fff;
    background-color:#B21E1C;
    border-color:rgba(0,0,0,0.2)

}
.btn-youtube:active,.btn-youtube.active,.open>.dropdown-toggle.btn-youtube{
    background-image:none
}
.btn-youtube.disabled,.btn-youtube[disabled],fieldset[disabled] .btn-youtube,.btn-youtube.disabled:hover,.btn-youtube[disabled]:hover,fieldset[disabled] .btn-youtube:hover,.btn-youtube.disabled:focus,.btn-youtube[disabled]:focus,fieldset[disabled] .btn-youtube:focus,.btn-youtube.disabled:active,.btn-youtube[disabled]:active,fieldset[disabled] .btn-youtube:active,.btn-youtube.disabled.active,.btn-youtube[disabled].active,fieldset[disabled] .btn-youtube.active{
    background-color:#DC2725;
    border-color:rgba(0,0,0,0.2)

}
.btn-youtube .badge{
    color:#DC2725;
    background-color:#fff

}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

// fix, create fake element so when you scroll to invalid question, it's not covered by menu
.question:before { 
    content: ''; 
    display: block; 
    position: relative; 
    width: 0; 
    height: 60px; 
    margin-top: -60px;

}


.brochure-message h4 {
    text-align: center;
}

.brochure-message p {
    text-align: justify;
}

.width-50-per{
    width:40%;
}
.short-form-controls [aifs-component]:not([type=radio]) {
    width: 50%;
}

.reference-view-mode > .tooltip > .tooltip-inner {
  max-width: 100px!important;
  width:auto!important;
}

.spinner-background, .lock-screen-background {
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    display: none;

    .show {
        display: block
    }
}

.spinner {
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    position: relative;
    margin-top: -32px;
    margin-left: -32px;
    background-image: url(/img/spinner.gif);
}

.lock-screen {
    position: relative;
    color: white;
    text-align: center;
    margin-top: 100px;
    font-weight: bold;
    font-size: 2em;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-row-agency-prospective {
    background: @pink-light !important;
}

.table-row-selected {
    background: #F2B0D4 !important;
}

.workqueues .panel-heading {
    .fa-stack-1x {
        color: #2e6da4;
    }

    .fa {
        cursor: pointer;
    }
}

.fa-stack.divider {
    border-left: 1px solid white;
    margin-left: .3em;
    width: 2px;
}

.clearQuickSerch {
    color: #d43f3a;
}

@media (min-width: @screen-md-min) {
    .find-aupair-modal {
        .modal-dialog {
            width: 700px;
        }
    }
}




.hostFamilyDocuments {

    a{
        cursor:pointer;
    }
}

.red_heart{
    color: red;
    padding-left: 1em;
}
/*
#interestedHF iframe {
    border: none;
}
*/
#interestedHF {
    .hostFamily{
        height: auto;
    }

    a.btnGreen {
        width: 10%;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 20px;
        font-size: 1.5rem;
        font-weight: 600;
    }

    #form1 {
        font-size: 15px !important;
    }
    
    #divSearch{
        display: none;
    }
}
.contact-basic-checkbox-property input[type=checkbox]{    
        margin-top: 30px;
}
.contact-basic-checkbox-property .checkbox{    
        margin-top: -50px;
} 
.contact-basic-checkbox-property .radio-inline span{    
        margin-left: 10px;
}

@media (min-width: 1100px) {
    .width-60-per{
        width:60%!important
    }
}


.dialog-grid {
    margin-left: -15px;
    margin-right: -15px;
    border: 1px solid transparent;

    &.has-error {
        border-color: #a94442;
    }

    &.has-success {
        border-color: #3c763d;
    }
}


.test-env-message {
    background: red;
    padding: 8px;
    width: 100%;
    font-size: 14px;
    color: white;
    text-align: center;
    font-family: Arial;

    a {
        color: white;
        font-weight: bold;
    }
}

.form_subheading span {
    display: block;
    border-bottom: 2px solid #8066AD;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.contract-fee-even {
    background-color: lightgrey;
    padding-top: 5px;
    padding-bottom: 5px;
}
.contract-fee-odd {
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
}
.contract-fee-heading {
    background-color: grey;
    color:whitesmoke;
    
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}
.contract-fee-heading-col {
    background-color: grey;
    color:whitesmoke;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}
.contract-fee-total {
    font-weight: bold;
}
.contract-warning-message {
    /*background-color: #FDD784;*/
    color:#D83D84;
    background-position: 30px center;
    background-repeat: no-repeat;
    margin-bottom: 10px;    
    font-size:20px;
    padding-left:20px;
}
.contract-warning-main-message{
    color: black;
    font-family: 'Open Sans', sans-serif;    
    font-size: 1.7rem;
    /*font-weight: bold;*/
}

.agt td {
    line-height: 2.5em;
}
.bg_green{
    background-color: #53B4A1;
    color: #ffffff;
}
.legal{padding-left: 15px;}
.legal > p{font-size: 14px;margin-top: 1em;margin-bottom: 1em;}
.legal > h1{font-size: 24px;font-weight: bold;text-align: center;margin-top: 1em;margin-bottom: 1em;}
.legal > ol{list-style-position: outside;list-style-type: decimal;font-size: 14px;}
.legal > ol > li{margin-bottom: 1em;font-weight: bold;}
.legal > ol > li > p{font-size: 14px;font-weight: normal;line-height: 1.2em;margin-top: 1em;margin-bottom: 1em;}
.legal > ol > li > ol{list-style-type: lower-roman;font-weight: normal;}
.legal > ol > li > ol > li{margin-bottom: 0em;margin-top: 0.5em;}
.legal > ol > ol > li{margin-bottom: 0em;margin-top: 0.5em;font-weight: normal!important}
.legal > ul{list-style-position: outside;list-style-type: disc;font-size: 14px;font-weight: normal!important;}
.legal > ul > li{margin-bottom: 0.5em;font-weight: normal!important;}
.legal > #content{padding: 8px 8px 8px 16px;}
.legal >.version { font-size: 14px; margin-top: 1em; margin-bottom: 1em; float: right; }

.button-hyperlink {
    background: none!important;
    color: blue;
    border: none;
    padding: 0!important;
    font: inherit;
    border-bottom: 1px solid blue;
    cursor: pointer;
}

.registrationTermsAndConditions {
    padding-top: 12px;
}
.position-field {
    padding-left: 15px;
}
/*.short-form-controls [type='checkbox'] {
    width: 50%;
}
.extra-short-form-controls{
    @media (min-width: @screen-small-min ) {
        width: 20%;
    }
}*/

@media print {
    #application-left-sidebar,
    #application-right-sidebar,
    #agency-left-sidebar,
    #management-left-sidebar,
    .page-sidebar-label,
    .navbar.navbar-static-top.advance-layout,
    .application-menu .nav-mobile {
        display: none;
    }

    body,
    .container-fluid.advance-layout,
    .content,
    .main-container {
        margin: 0 !important;
        padding: 0 !important;
        width: auto !important;
        height: auto !important;
    }
}

.match_table {
    padding: 15px;
    text-align: left;
    border: 1px solid #D3D3D3;
}

.agreementTable {
    margin-top: 15px;
    margin-bottom: 20px;
}

.view-transaction .control-label {
    text-align: left!important;
}

.application-grid-pagination {
    float: right;

    select {
        font-size: 0.95em;
    }

    .btn-group {
        float: right;
        margin: 22px 8px;
    }

    .app-list-pagination {
        float: right;
    }
}

.pagination {
    > li {
        > select {
            position: relative;
            float: left; // Collapse white-space
            padding: @padding-base-vertical @padding-base-horizontal;
            line-height: @line-height-base;
            text-decoration: none;
            color: @pagination-color;
            background-color: @pagination-bg;
            border: 1px solid @pagination-border;
            margin-left: -1px;
        }

        &:first-child {
            > select {
                margin-left: 0;
                .border-left-radius(@border-radius-base);
            }
        }

        &:last-child {
            > select {
                .border-right-radius(@border-radius-base);
            }
        }
    }

    > li > select {
        &:hover,
        &:focus {
            z-index: 3;
            color: @pagination-hover-color;
            background-color: @pagination-hover-bg;
            border-color: @pagination-hover-border;
        }
    }

    > .active > select {
        &,
        &:hover,
        &:focus {
            z-index: 2;
            color: @pagination-active-color;
            background-color: @pagination-active-bg;
            border-color: @pagination-active-border;
            cursor: default;
        }
    }

    > .disabled {
        > select,
        > select:hover,
        > select:focus {
            color: @pagination-disabled-color;
            background-color: @pagination-disabled-bg;
            border-color: @pagination-disabled-border;
            cursor: @cursor-disabled;
        }
    }
}



.float-right{
    float:right;
}
.app-list-pagination {
    margin-right: 1px;
}
.pink-icon {
    color: #e23d99 !important;
    background-position: 0;
    border-color: #e23d99;
}
.color-white {
    color: white !important
}
.application-review-started-other {
    color: red !important;
}
.application-review-started-me {
    color: green !important;
}
.width-75-px{
    width:75px;
}
.left-panel-applications ~ .main-container {
    padding-left: 0px;
}
.expanded-menu-swich {
    position: relative;
    z-index: 1;

    button {
        position: absolute;
        top: 0px;
        left: 0px;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
    }
}
.default-wq-icon {
    color: #2e6da4 !important;
    font-size: 18px;
}
.default-wq-text {
    color: #337ab7 !important;
    /*background-color: #2e6da4;*/
    color: white;
}
.wq-list-icon {
    font-size: 18px;
}
.wq-list-text {
    /*color: #337ab7 !important;*/
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1px;
}

.extensionOptionHeader {
    border-top: 2px solid black;
    margin-top: 20px;
    padding-top: 15px;
    font-weight: bold;
}

.fixed-badge {
    min-height: 15px !important;
    display: inline-block !important;
    margin-left: 5px;
}

.margin-top-50 {
    margin-top: 40px;
    z-index: 1009;
}

.extensionHeaderParagraph {
    border-bottom: 2px solid black;
    margin-bottom: 20px;
    padding-bottom: 15px;
}

.fontBold {   
    font-weight: bold;
}

.fee-sheet-form {
    border: 1px solid lightgrey;

    td {
        border-right: 1px solid lightgrey;
    }

    th {
        border-right: 1px solid lightgrey;
    }
}

/*.territory tbody:after {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
}*/

.territory tbody td:first-child, 
.territory tbody th:first-child {
    padding-left: 20px;
}



.fee-regions {
    .millerColumns {
        border: 1px solid #ddd;


        .title {
            font-weight: bold;
            text-align: center;
            padding: 10px;
        }

        .content {
            height: 400px;
            overflow: auto;
        }

        .middle {
            border: solid #ddd;
            border-width: 0px 1px;
        }
    }

    .fee-region-country {
        padding: 8px;
        border: 1px solid #ddd;
        color: #888;
        margin: 2px 5px;
        border-radius: 4px;

        &.selected {
            background-color: #D83D84;
            color: white;
        }

        .btn-group {
            margin-top: -5px;
            margin-right: 10px;
            /*button {
                color: #fff;
                background-color: #EB82BD;
                border-color: #da2b8a;
            }*/
        }

        .btn-sm {
            margin-top: -5px;
            /*color: #fff;
            background-color: #EB82BD;
            border-color: #da2b8a;*/
        }
    }


    .fees {
        border: 1px solid #ddd;
        margin-top: 20px;

        .selected {
            background: #D83D84;
            color: white;
        }

        .notification {
            text-align: center;
            padding-top: 26px;
        }

        th .btn-group {
            margin-left: 6px;
        }
    }
}

.longString {
    overflow-wrap: break-word;
}

.select-multi-read-only {
    background-color: #2dadef;
    color: #fff;
    padding: .2em .6em;
    margin: 0 .15em .25em;
    border-width: 1px;
    border-style: solid;
    float: left;
    line-height: normal;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    font-weight: 300;
    cursor: pointer;
}

.has-footer {
    padding-bottom: 66px;
}

.contract-fee-table {
    border: 1px solid #ddd;

    thead > tr > th {
        border: 1px solid #ddd;
    }

    tbody > tr > td {
        border: 1px solid #ddd;
    }
}

.fee-sheet-page {
    height: 100%;
    overflow-y: auto;
}

.no-padding-left {
    padding-left: 0 !important;
}

.additional-fee {
    margin-bottom: 20px;
}

.additional-fee > thead,
.additional-fee > tbody,
.additional-fee > tfoot {
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
}

.additional-fee > thead > tr > th,
.additional-fee > tbody > tr > th,
.additional-fee > tfoot > tr > th,
.additional-fee > thead > tr > td,
.additional-fee > tbody > tr > td,
.additional-fee > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
}

input[type="radio"][disabled],
.form-control[disabled],
input[type="checkbox"][disabled] {
    cursor: auto !important;
}
.interview-report > textarea .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: white;
}
.add-new-contract-fee {
    padding-left: 5px;
    cursor:pointer;
}

.tob_no_bullet > li,
.tob_no_bullet > li > ul > li
{
    list-style-type: none;
}

.country-selection {
    padding-bottom: 30px;
}

.contacts-appGrid {
    height: auto !important;
}

.fixed-column-table-container {
    width: 100% !important;
    overflow-y: auto !important;
    margin-top: 0px !important;

    table {
        margin-top: -2px;
        margin-left: -1px;

        th, td {
            margin: 0;
        }

        th {
            position: relative;
            z-index: 1;
        }

        th, .fixed-cell {
            background: #eee;
        }

        .cross {
            position: relative;
            z-index: 1;
        }
    }
}
.z-index-2 {
    z-index: 2!important;
}

@media (min-width: @screen-md-min) {
    .extension-border-right {
        border-right: 1px solid black;
    }
}

.padding-top-left20 {
    padding-left: 20px;
    padding-top: 20px;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.borderless td, .borderless th {
    border: none !important;
    padding-left: 0px !important;
}

.border-top-only {
    border-top: 1px solid #ddd;
}

.padding-bottom-0 {
    padding-bottom: 0px !important;
}

.agreement {
    height: 100%;
    overflow: auto;

    @media screen {
        padding: 0px 20px;
    }
}
.agreement-text {
    background: whitesmoke;
    padding: 5px;
}
.inactive-territory {
    background-color: lightpink;
}
.application-menu-tooltip > .tooltip > .tooltip-inner {
    max-width: 350px;
    color: #fff;
    text-align: left;
    background-color: #000;
}
.text-color-red {
    color: red;
}

.local-fee-amount {
    float: left;
    padding-inline-end:inherit;
}
.padding-10-15 {
    padding: 10px 15px;
}

h5.subtitle {
    margin-top: 0;
    margin-bottom: 20px;
}